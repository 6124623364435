.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 0 auto;
}

.link {
    text-decoration: none;
}


.cards {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.button {
    background-color: rgba(23, 34, 67, 1);
    margin-top: 2rem;
    color: white;
    padding: 1rem 2rem;
    border-radius: 1rem;
    border-style: none;
    box-shadow: 0px 1px 1.5rem black;
    animation: all .3s;
    cursor: pointer;
    font-size: 1.5rem;
}

.button:hover {
    box-shadow: 0px 5px 1.5rem black;
    transform: translateY(-3px);
}

.button:active {
    box-shadow: 0px 1px 1.5rem black;
    transform: translateY(0px);
}

