.card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* width: 30%; */
    width: 45rem;
    height: 52rem;
    margin: 2rem 1rem;
    border-radius: 1rem;
    border-style: none;
    box-shadow: 0px .5rem 2rem black;
    transition: all .4s;
    cursor: pointer;
}



.image {
    width: 45rem;
    border-radius: 1rem 1rem 0px 0px;
}

.blocText {
    width: 40rem;
    margin: 2rem 2rem;
}

.title {
    width: 100%;
    color: rgba(23, 34, 67, 1);
    font-size: 2.5rem;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;    
}

.excerpt {
    font-size: 1.5rem;
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;    
    font-weight: 100;
    width: 100%;
    color: black;
}

.card:hover {
    transform: translateY(-5px);
}

.card:active {
    transform: translateY(0px);
}


/* @media (max-width: 1510px) {
    .card {
        width: 45%;
    }
} */