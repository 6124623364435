.histoire {
    position: relative;
    display: block;
    height: auto;
    /* background-color: rgba(23, 34, 67, 1); */
    background-image: linear-gradient(to right bottom,rgba(23, 34, 67, 1), rgba(23, 34, 67, 0.95));
    /* margin-top: 5rem; */
    padding: 40rem 0rem; /* Add padding to separate the margin and to avoid merge collapsing */
    clip-path: polygon(0 25%, 100% 0, 100% 75%, 0% 100%);    
}

.histoireTitle {
    text-align: center;
    font-size: 4rem;
    color: white;
    margin: 8rem 0rem;
}

.histoireTxt {
    text-align: justify;
    text-overflow: wrap;
    font-size: 2.5rem;
    color: white;
    margin: 5rem 3rem 10rem 3rem;
}

.blocs {    
    display: flex;
    flex-wrap: wrap;    
    justify-content: space-evenly;
}

.histoirePic {
    margin: 1rem 1rem;
    height: 25rem;
    border-radius: 3px;
}

.histoirePic:hover {
    transform: scale(1.2);
    -webkit-filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(0px 10px 3px rgba(0, 0, 0, 0.8));
}

.gp {
    color:white; 
    text-decoration:none; 
    font-weight:800
}

.gp:hover {
    text-decoration: underline;
}


@media (max-width:1900px) {
    .histoire {
        clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);    
    }
}