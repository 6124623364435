.article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 2rem;
    width: 80%;
    margin: 10rem auto;
    text-align: left;
}

.article img {
    width: 100%;
    height: 100%;
}

.article p {
    display: flex;
    width: 100%;
}

