.bloc {
    border-radius: 3rem;
    height: 55rem;
    width: 48rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 5rem;
    position: relative;
    transition: all .5s;
}

.servicesPic {
    width: 15rem;
}

.serviceTxt {
    text-align: center;
    text-overflow: wrap;
    width: 30rem;
    font-size: 3rem;
}

.arrow {
    width: 10rem;
    height: 5rem;
}

.bloc:hover {
    box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.8);
    transform: translateY(-5px);
}

.bloc:hover .arrow {
    animation: slideLeftRight 1.5s infinite; 
}


@keyframes slideLeftRight {
    0% {
        transform: translateX(0%);
    }
        
    20% {
        transform: translateX(-50%);
    }


    60% {
        transform: translateX(50%);
    }

    100% {
        transform: translateX(0%);
    }
}

.bloc::after {
    content: "";
    position: absolute;
    /* background-color: rgb(255, 255, 255); */
    border-radius: 3rem;
    height: 100%;
    width: 100%;
    z-index:-3;
}

.bloc:hover::after {
    animation: blocExplode 1s infinite;
    animation-fill-mode: backwards;
}

.bloc:active { 
    transform: translateY(5px);
    box-shadow: 0 .5rem .5rem rgba(0, 0, 0, 0.2); 
}


@keyframes blocExplode {
    0% {
        transform: scaleX(1) scaleY(1);
    }
    100% {
        transform: scale(1.3);
        background-color: rgb(192, 192, 192);
        opacity: 0;
    }
}

@media (max-width:300px) {
    .bloc {
        height: 50rem;
        width: 38rem;
    }
}