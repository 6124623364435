.header {
    position: relative;
    height: 95vh;
    clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);  
    /* background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.8), rgba(25, 64, 86, 0.8)), url('../images/header1.png'); */
    background-size: cover; 
    background-position: bottom;
    z-index: 1;
}


/* .logo {
    position: fixed;
    background-color: hsla(0, 0%, 50%, 0.25);
    z-index: 1; 
    height: 200px;
    margin: 10px 10px;
    animation: slideInRight 1.5s;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
} */

.texte {
    position: absolute;
    /* text-transform: uppercase; */
    z-index: 1;
    /* background-color: red; */
    /* background-color: red; */
    top: 45%; 
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(23, 34, 67, 1);
    text-align: center;
}

.main-title {
    display: block;
    font-size: 6rem;
    font-family: 'Marcellus', serif;
    letter-spacing: 1.5rem;
    animation: slideInLeft 1.5s;
}

.slogan {
    display: block;
    font-size: 5rem;
    margin-top: 1rem;
    letter-spacing: .5rem;
    animation: slideInRight 1.5s;
}
  
@keyframes slideInLeft {
0% {
    transform: translateX(-100%);
    opacity: 0;
}

80% {
    transform: translateX(10%);
}

100% {
    transform: translateX(0);
    opacity: 1;
}
}

@keyframes slideInRight {
0% {
    transform: translateX(100%);
    opacity: 0;
}

80% {
    transform: translateX(-10%);
}

100% {
    transform: translateX(0);
    opacity: 1;
}
}

/* https://developer.mozilla.org/fr/docs/Learn/CSS/Styling_text/Styling_links */
.button, .button:link, .button:visited { 
    display: inline-block;    
    color: inherit;
    text-decoration: none;
    background-color: white;
    margin-top: 6rem;
    border-radius: 10rem;
    font-size: 2rem;
    padding: 1rem 2rem;
    transition: all .2s;
    position: relative;
    animation: slideInBottom 0.5s;
    /* width: ; */
}

.button:hover { 
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); 
}

.button:active { 
    transform: translateY(-1px);
    box-shadow: 0 .5rem .5rem rgba(0, 0, 0, 0.2); 
}

.button::after {
    content: ""; /* we have to specify it */
    display: inline-block;    
    border-radius: 10rem;
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute; /* realative to the button */
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .3s;
}

.button:hover::after {
    opacity: 0;
    transform: scaleX(1.3) scaleY(1.5);
}

@keyframes slideInBottom {
    0% {
        transform: translateY(50%);
        opacity: 0;
    }
    
    80% {
        transform: translateY(-5%);
    }
    
    100% {
        transform: translateY(0);
        opacity: 1;
    }
    }
    