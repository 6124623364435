.section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 10rem 0rem;
}

.blocText {
    background-image: linear-gradient(to right bottom, rgba(23, 34, 67, 1), rgba(23, 34, 67, 0.95)); 
    box-shadow: 0px 1rem 2rem black;   
    border-radius: 5rem;
    padding: 2rem;
    margin: 0rem 5rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.texte {
    color: white;
    font-size: 2.5rem;
}

.sectionsubtitle {
    color: white;
    font-size: 3rem;
    text-align: center;
}

.blocImages {
    height: 30rem;
    /* background-color: red; */
    position: relative;
    width: 85rem;
    display: flex;
    justify-content: center;
}


.image_0 {
    position: absolute;
    -webkit-filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(0px 1rem 1rem rgba(0, 0, 0, 0.8));
    right: 0rem;
    top: 0rem;
    height: 100%;
}

.image_1 {
    position: absolute;
    -webkit-filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(0px 1rem 1rem rgba(0, 0, 0, 0.8));
    top: 10rem;    
    /* left: 15rem; */
    height: 100%;
}

.image_2 {
    position: absolute;
    -webkit-filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(0px 1rem 1rem rgba(0, 0, 0, 0.8));
    top: -10rem;
    left: 5rem;
    height: 100%;
}

.button, .button:link, .button:visited { 
    display: inline-block;    
    color: inherit;
    text-decoration: none;
    background-color: white;
    margin-top: 2rem;
    border-radius: 10rem;
    font-size: 2rem;
    padding: 1rem 2rem;
    transition: all .2s;
    position: relative;
    /* width: ; */
}

.button:hover { 
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); 
}

.button:active { 
    transform: translateY(-1px);
    box-shadow: 0 .5rem .5rem rgba(0, 0, 0, 0.2); 
}

.button::after {
    content: ""; /* we have to specify it */
    display: inline-block;    
    border-radius: 10rem;
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute; /* realative to the button */
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .3s;
}

.button:hover::after {
    opacity: 0;
    transform: scaleX(1.3) scaleY(1.5);
}

@media (max-width: 2150px) {
    .section {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .blocImages {
        width: 85%;
    }
    
    .blocText {
        margin: 15rem 0rem;
        width: 85%;
   }
}