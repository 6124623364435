.suggestions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5rem;
}

/* .suggestionText {
    font-size: 2.5rem;
    color: rgba(23, 34, 67, 1);
} */

.cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.link {
    text-decoration: none;
}

