.wrap {
    display: flex;
    justify-content: center;
}

.contact {
    position: relative;
    /* margin-bottom: 10rem; */
    height: 110rem;
    width: 72rem;
    perspective: 250rem;
    -moz-perspective: 250rem;
}

.card {
    border-radius: 5rem;
    box-shadow: 0px 1.5rem 3rem rgba(0, 0, 0, 0.8);
    /* margin: 5rem 0rem; */
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transform-style: preserve-3d;
    transition: all .9s ease-out;
}

.cardFront {
    background-image: linear-gradient(to right bottom,rgba(23, 34, 67, 1), rgba(23, 34, 67, 0.95));
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 4.5rem;
}

.cardBack {
    background-image: linear-gradient(to right bottom,rgba(23, 34, 67, 1), rgba(23, 34, 67, 0.95));
    transform: rotateY(180deg);
}

.frontText {
    text-align: center;
    font-size: 2.2rem;
    text-wrap: wrap;
    width: 80%;
}
/* .contact:hover .cardFront {
    transform: rotateY(-180deg);
}

.contact:hover .cardBack {
    transform: rotateY(0deg);
} */


.messageTitle {
    text-align: center;
    color: white;
    font-size: 2.5rem;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form div {
    margin: 0rem 0rem;
}


.form label {
    display: block;
    color: white;
    font-size: 2.5rem;
    margin: 0px 0px .5rem 0rem;
}

.form input, .form textarea {
    width: 50rem;
    resize: none;
    font-size: 1.5rem;
    font-family: 'Noto Sans JP', 'Open Sans', sans-serif;
}

.form .invalid {
    border: .1rem solid #b40e0e;
    background-color: #fddddd;
}

.form input:focus {
    outline: none !important;
    border: .1rem solid white;
    box-shadow: 0 0 1rem #719ECE;
}

.form input.invalid:focus, .form textarea.invalid:focus {
    outline: none !important;
    border: .1rem solid red;
    box-shadow: 0 0 1rem red;
}

.form .validText {
    margin: 0rem 0rem;
    color:green
}

.form .validationFeedback {
    min-height: 3rem; 
    margin-top: .3rem;
}

.form .invalidText {
    margin: 0rem;
    font-size: 1.6rem;
    color:red
}

.form .validImage {
    margin: 0rem;
}

.form .validImage img {
    width: 1.9rem;
}


.form button {
    margin: 2rem 0px;
    box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.2);
    color: rgba(23, 34, 67, 1);
    background-color: white;
    border-style: none;
    font-family: 'Noto Sans JP', 'Open Sans', sans-serif;
    font-size: 2rem;
    border-radius: 2rem;
    padding: 1rem 3rem;
    cursor: pointer;
    transition: all .3s;
}

.form button:hover {
    transform: translateY(-.3rem);
    box-shadow: 0 1rem 2.5rem rgba(255, 255, 255, 0.2); 
}

.form button:active {
    transform: translateY(0px);
    box-shadow: 0 .5rem .5rem rgba(255, 255, 255, 0.2); 
}


.logo {
    /* display: block; */
    /* background-color: red; */
    font-size: 3rem;
    color: white;
    font-family: 'Marcellus';
    letter-spacing: 1rem;
}

.button, .button:link, .button:visited { 
    color: rgba(23, 34, 67, 1);
    text-decoration: none;
    background-color: white;
    border-radius: 10rem;
    font-size: 4rem;
    padding: 2rem 4rem;
    transition: all .5s;
    position: relative;
    /* animation: slideInBottom 0.5s; */
    /* width: ; */
}

.button:hover { 
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); 
}

.button:active { 
    transform: translateY(-1px);
    box-shadow: 0 .5rem .5rem rgba(0, 0, 0, 0.2); 
}

.button::after {
    content: ""; /* we have to specify it */
    display: inline-block;    
    border-radius: 10rem;
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute; /* realative to the button */
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .3s;
}

.button:hover::after {
    opacity: 0;
    transform: scaleX(1.3) scaleY(1.5);
}

@media (max-width: 1200px) {
    .wrap {
        margin-top: 10rem;
    }
}
