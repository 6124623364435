.services {
    position: relative;
    display: block;
    height: auto;
    /* background-color: rgba(23, 34, 67, 1); */
    /* margin-top: 6rem; */
    padding: 30rem 0rem; /* Add padding to separate the margin and to avoid merge collapsing */
    clip-path: polygon(0 25%, 100% 0, 100% 75%, 0% 100%);    
}

.servicesTxt {
    text-align: center;
    font-size: 4rem;
    color: white;
    margin: 5rem 0rem;
}

.blocs {    
    display: flex;
    flex-wrap: wrap;    
    justify-content: space-evenly;
    height: 70%;
}

.blocs a {
    text-decoration: none;
}

@media (max-width: 1200px) {
    .services {
        /* margin-top: 35rem; */
        clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);    
    }
}