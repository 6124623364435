.section {
    display: flex;
    /* height: 80rem; */
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;    
    position: relative;
    margin-bottom: 5rem;
}

/* to overcome the clip path problem that crops the picture  */

/* 
.section::after {  
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: linear-gradient(to right bottom, rgba(23, 34, 67, 1), rgba(23, 34, 67, 0.95)); 
    clip-path: polygon(0 25%, 100% 0, 100% 75%, 0% 100%);    

} */

.sectionsubtitle {
    color: black;
    font-size: 3rem;
    text-align: center;
}

.containerTexte {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
}

.sectionTexte {
    text-align: center;
    font-size: 2.5rem;
    color: rgba(23, 34, 67, 1);
    margin: 0px 5rem;
}

.sectionImage {
    top: 10rem;
    -webkit-filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.8));
    height: 60rem;  
    transform-style: preserve-3d;
    margin: 4rem;
}

.button, .button:link, .button:visited { 
    display: inline-block;    
    color: white;
    text-decoration: none;
    background-color: rgba(23, 34, 67, 1);
    margin: 4rem 0rem;
    border-radius: 10rem;
    font-size: 2rem;
    padding: 1rem 2rem;
    transition: all .2s;
    position: relative;
    /* width: ; */
}

.button:hover { 
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); 
}

.button:active { 
    transform: translateY(-1px);
    box-shadow: 0 .5rem .5rem rgba(0, 0, 0, 0.2); 
}

.button::after {
    content: ""; /* we have to specify it */
    display: inline-block;    
    border-radius: 10rem;
    background-color: rgba(23, 34, 67, 1);
    width: 100%;
    height: 100%;
    position: absolute; /* realative to the button */
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .3s;
}

.button:hover::after {
    opacity: 0;
    transform: scaleX(1.3) scaleY(1.5);
}

@media (max-width: 1550px) {
    .section {
        flex-direction: column;
    }

    .containerTexte {
        width: 80%;
        /* clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);     */
    }
}