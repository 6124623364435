.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure it takes the full height of its parent container */
    margin: 10rem;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Border color and opacity */
    border-top: 4px solid rgba(23, 34, 67, 1); /* Border color of the spinner */
    border-radius: 50%; /* Makes it a circle */
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite; /* Animation properties */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }