.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70rem;
    clip-path: polygon(0 25%, 100% 0, 100% 75%, 0% 100%);    
}

.logoTexte {
    font-size: 3rem;
    color: white;
    font-family: 'Marcellus';
    text-wrap: wrap;
    text-align: center;
    letter-spacing: 1rem;
}

.logoFooter {
    display: flex;
    /* background-color: red; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.logoPic {
    height: 25rem;
    /* background-color: red; */
}

.breakLine {
    width: 3px;
    background-color: white;
    height: 30rem;
    margin: 0px 3rem;
}

.menuFooter {
    display: flex;
    flex-direction: row;
    margin-right: 3rem;
}

.footerUl {
    list-style: none;
    display: flex;
    /* background-color: red; */
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
}

.footerUl li {
    font-size: 3rem;
}

.footerUl li a:link, .footerUl li a:visited {
    text-decoration: none;
    color: white;
    transition: all .2s;
}

.footerUl li a::after {
    content: '';
    display: block;
    width: 0;
    height: .3rem;
    background-color: white; 
    transition: all 0.3s ease;
}

.footerUl li a:hover::after {
    width: 100%;
}

.languesUl {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.languesUl li {
    margin: 0px .5rem;
    font-size: 1.6rem;
}

.languesUl li a:link, .languesUl li a:visited {
    text-decoration: none;
    color: rgba(23, 34, 67, 1);
}

.languesUl li a::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background-color: rgba(23, 34, 67, 1);
    transition: all 0.3s ease;
}

.languesUl li a:hover::after {
    width: 100%;
}

@media (max-width: 1200px) {
    .footer {
        margin-top: 10rem;
        clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);    
    }
}

@media (max-width: 300px) {
    .logoPic {
        height: 20rem;
        /* background-color: red; */
    }
    .logoTexte {
        font-size: 2rem;
    }
    .footerUl li {
        font-size: 2rem;
    }    
}