.logo {
    position: fixed;
    z-index: 20; 
    height: 20rem;
    border-radius: 1rem;
    margin: 1rem 1rem;
    animation: slideInRight 1.5s;
    box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.2);
    -webkit-filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(0px 10px 3px rgba(0, 0, 0, 0.8));
    cursor: pointer;
    transition: all .4s;
}

.logo:active {
    transform: translateY(5px);
    box-shadow: 0 .5rem 2rem rgba(0, 0, 0, 0.2); 
}

.appearLogo {
    background-color: rgba(255, 255, 255, 0.6);
}

.disappearLogo {
    background-color: hsla(0, 0%, 50%, 0.25);
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    
    80% {
        transform: translateX(-10%);
    }
    
    100% {
        transform: translateX(0);
        opacity: 1;
    }
    }

.background {
    position: fixed;
    height: 20rem;
    width: 20rem;
    opacity: 0;
    margin: 1rem 1rem;
    z-index: 10;
    transition: all .4s;
    background-image: radial-gradient(rgba(23, 34, 67, 1), rgba(23, 34, 67, 0.95));
    border-radius: 10rem;
}

.appearBackground {
    animation: animateOpenBackground .6s ease-out forwards;
}

.disappearBackground {
    animation: animateCloseBackground .6s ease-out forwards;
}

@keyframes animateOpenBackground {
    0% {
        transform: scale(1);
        opacity: 0.2;
    }
    100% {
        transform: scale(30);
        opacity: 1;
    }
}

@keyframes animateCloseBackground {
    0% {
        transform: scale(30);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.naviga {
    width: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 15; 
}

.menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

.appearMenu {
    animation: animateOpenMenu .6s ease forwards;
}

.disappearMenu {
    animation: animateCloseMenu .6s ease-out forwards;
}

@keyframes animateOpenMenu {
    0% {
        width: 0;
        opacity: 0;
    }
    100% {
        width: 100%;
        opacity: 1;
    }
}

@keyframes animateCloseMenu {
    0% {
        width: 100%;
        opacity: 1;
    }
    100% {
        width: 0;
        opacity: 0;
    }
}



.menu li {
    margin: 2rem 0rem;
}


.menu li .link:link, .menu li .link:visited {
    display: inline-block; /* to make the translation work */ 
    text-decoration: none;
    color: white;
    font-size: 3.5rem;
    padding: .5rem 1rem;
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, white 50%);
    background-size: 300%;
    transition: all .7s;
}

.menu li .link:hover, 
.menu li .link:active {
    color: rgba(23, 34, 67, 1);
    background-position: 100%;
    transform: translateX(1rem);
}

@media (max-width: 300px) {
    .logo {
        height: 15rem;
    }
}

@media (max-height: 1000px) {
    .logo {
        height: 15rem;
    }
}