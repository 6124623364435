.section {
    display: flex;
    /* height: 80rem; */
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    /* flex-wrap: wrap; */
    position: relative;
    /* margin-bottom: 4rem; */
}

/* to overcome the clip path problem that crops the picture  */

/* 
.section::after {  
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: linear-gradient(to right bottom, rgba(23, 34, 67, 1), rgba(23, 34, 67, 0.95)); 
    clip-path: polygon(0 25%, 100% 0, 100% 75%, 0% 100%);    

} */

.containerTexte {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
}

.sectionsubtitle {
    color: black;
    font-size: 3rem;
    text-align: center;
}


.sectionTexte {
    text-align: center;
    font-size: 2.5rem;
    color: rgba(23, 34, 67, 1);
    margin: 0px 5rem;
}

.sectionImage {
    top: 10rem;
    -webkit-filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.8));
    height: 60rem;  
    transform-style: preserve-3d;
}



@media (max-width: 1550px) {
    .section {
        flex-direction: column-reverse;
    }
    .containerTexte {
        width: 80%;
        /* clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);     */
    }
}