.clients {
    display: block;
    /* height: auto; */
    /* margin-top: 6rem; */
    /* background-color: red; */
}

.logos {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15rem;
    margin-left: 1rem;
    margin-right: 1rem;
    justify-content: space-evenly;
}

.logo {
    height: 20rem;
    /* background-color: red; */
    filter: grayscale(100%);    
}

.logo:hover {
    transform: scale(1.1);
    -webkit-filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(0px 10px 3px rgba(0, 0, 0, 0.8)) grayscale(100%);
}


.confiance {
    text-align: center;
    font-size: 4rem;
    margin: 0px 0px;
    color: rgba(23, 34, 67, 1);
}

@media (max-width:800px) {
    .logos {
        margin-top: 8rem;
    }
    .clients {
        margin: 6rem 0rem;
    }
    
}