html {
  /* browser font size 16px: 10 = 1 rem donc 10/16 */
  font-size: 10px; 
  /* for iOS */
  -webkit-text-size-adjust: none; 
}

body {
  margin: 1vw;
  padding: 0;
  height: auto;
  width: auto;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@media (max-width: 800px) {
  html {
    /* browser font size 16px: 5px = 1 rem donc 5/16 = 31.25%*/
    font-size: 5px; 
  }
}

@media (min-width: 500px) and (max-height: 1000px) {
  html {
    font-size: 5px; 
  }
}